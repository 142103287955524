   
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import tel from '../../assets/img/tel.png'
import talk from '../../assets/img/controller.svg'
import ordi from '../../assets/img/ordi.png'
import net from '../../assets/img/net.png'

// core components

function SectionNucleoIcons() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
        <Container>
          <Row style={{color: 'rgba(255, 255, 255, 0.7)'}}>
            {/* <Col lg="6" md="12">
              <h2 className="title">Solutions digitales</h2>
              <br />
              <p className="description">
                Envol Design est une agence de marketing basée au coeur de Paris 
                qui saura vous proposer des solutions digitales adaptées à vos besoins.
              </p>
              <br /> */}
              {/* <Button
                className="btn-round"
                color="danger"
                href="#"
                target="_blank"
              >
                Demandez conseil
              </Button> */}
              {/* <Button
                className="btn-round ml-1"
                color="danger"
                href="#"
                outline
                target="_blank"
              >
                Prendre un rendez-vous
              </Button> */}
            {/* </Col> */}
            <Col lg="12" md="12">
            <h2 className="title">Solutions digitales</h2>
              <br />
              <p className="description">
                Envol Design est une agence de marketing basée au coeur de Paris 
                qui saura vous proposer des solutions digitales adaptées à vos besoins.
              </p>
              <br></br>
            <Row>
      <Col span={6}>
        <div style={{marginLeft: 30}}>
          <img src={tel} alt="mobile"></img>
          <h3>Application mobiles</h3>
          <p>Nous concevons et réalisons vos applications natives sur les principaux OS mobiles : iOS et Android.</p>
        </div>
      </Col>
      <Col span={6}>
      <div style={{marginLeft: 30}}>
          <img src={ordi} alt="responsive"></img>
          <h3>Sites responsives</h3>
          <p>Nous réalisons des sites responsives et mettons en place des designs pensés pour le multi-écran.</p>
        </div>
      </Col>      
      <Col span={6}>
      <div style={{marginLeft: 30}}>
          <img src={net} alt="Webapp"></img>
          <h3>Webapp</h3>
          <p>Nous rendons vos sites web accessibles depuis les smartphones, ils sont ouverts sans passer par un navigateur.</p>
        </div>
      </Col>      
      {/* <Col span={6}> */}
      {/* <div style={{marginLeft: 30}}> */}
      {/* <i style={{color: "orange"}} className="fa fa-heart heart" src={talk} /> by Envol Design */}
{/* 
          <img  style={{color: "orange"}} src={talk} alt="bot"></img>

          <h3> Jeux </h3>
          <p>Nous développons des jeux pour ordinateurs et mobiles.</p>
        </div>
      </Col> */}
    </Row>            
    </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionNucleoIcons;
