  import React, { Component } from "react";

  // reactstrap components
  import { Button, Container, Row, Col, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormText } from "reactstrap";
  
  import main from '../../assets/img/logo-large.svg'
  
  import * as emailjs from 'emailjs-com'
  
  // core components
  
    class SectionDownload extends Component {
  
      constructor(props){
        super(props);
        this.state = {
          modal: false,
          message: "",
          firstname:"",
          lastname:"",
          email: "",
          file:'',
          sent: false
        }
        this.firstnameChange = this.firstnameChange.bind(this)
        this.fileChange = this.fileChange.bind(this)
        this.lastnameChange = this.lastnameChange.bind(this)
        this.emailChange = this.emailChange.bind(this)
        this.messageChange = this.messageChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggle = this.toggle.bind(this)
      }

      toggle(){
        this.setState({modal: !this.state.modal})
      }

      firstnameChange(e){
        this.setState({firstname: e.target.value})
      }

      lastnameChange(e){
        this.setState({lastname: e.target.value})
      }

      emailChange(e){
    
        this.setState({email: e.target.value})
      }
      messageChange(e){
    
        this.setState({message: e.target.value})
      }

      fileChange(e){
    
        this.setState({file: e.target.value})
      }
  
      handleSubmit(event) {
        event.preventDefault();


        let templateParams = {
          reply_to: this.state.email,
          from_name: [this.state.firstname,this.state.lastname].join(' '),
          to_name: "envoldesign",
          message_html: this.state.message +".<br></br><br></br> from: "+this.state.email,
         }

         if(this.state.email && this.state.firstname && this.state.lastname && this.state.message !== "" ){
          emailjs.send(
            'gmail',
            'resume',
             templateParams,
            'user_T1h15TiFU10JIjO1F7ZAH'
           )
           this.setState({sent: true, modal: false})
        } else {
          this.setState({sent: "error"})
        }
      };
  
  render(){
    
    return (
      <>
        <div className="section">
          <Container className="text-center">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Vous aimeriez faire partie de l'equipe?</h2>
                {/* <p className="description">
                  Plus qu'une Great Place To Work, Envol Design se vit en interne comme une véritable Best Place to Grow.
                </p> */}
                <p>
                Une carrière chez Envol Design, c'est plusieurs trajectoires possibles, en fonction de vos aspirations.
                Que vous vouliez devenir développeur, craftsman, architecte, consultant, manager, super-expert, ou développer la relation avec nos clients, nous vous accompagnons.
                </p>  
              </Col>
              <Col className="ml-auto mr-auto download-area" md="5">
                <p>Rejoingez-nous:</p>
                <Button
                  className="btn-round"
                  color="link"
                  onClick={this.toggle}
                >
                  <img style={{height: "150px"}}className="img-fluid" src={main} alt="logo"></img>
                </Button>
              </Col>
            </Row>
            <footer className="footer footer-black footer-white">
  
              <Row>
                <div className="credits ml-auto">
                  <span className="copyright">
                    {/* © {new Date().getFullYear()}, made with{" "} */}
                    <i style={{color: "orange"}} className="fa fa-heart heart" /> Envol Design
                  </span>
                </div>
              </Row>
  
            </footer>
  
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
              <ModalHeader toggle={this.toggle}>Information</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup row>
                      <Label for="lastname" sm={2}>Nom</Label>
                      <Col sm={10}>
                        <Input type="text" name="lastname" id="lastname"  onChange={(e) => this.lastnameChange(e)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="firstname" sm={2}>Prénom</Label>
                      <Col sm={10}>
                        <Input type="text" name="firstname" id="firstname" onChange={(e) => this.firstnameChange(e)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="email" sm={2}>Email</Label>
                      <Col sm={10}>
                        <Input type="email" name="email" id="email"  onChange={(e) => this.emailChange(e)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleText" sm={2}>Vos objectifs</Label>
                      <Col sm={10}>
                        <Input type="textarea" name="text" id="exampleText" onChange={(e) => this.messageChange(e)} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="file" sm={2}>Votre CV</Label>
                      <Col sm={10}>
                        <Input type="file" name="file" id="file" onChange={(e) => this.fileChange(e)} />
                        {/* <FormText color="muted">
                          This is some placeholder block-level help text for the above input.
                          It's a bit lighter and easily wraps to a new line.
                        </FormText> */}
                      </Col>
                    </FormGroup>
                    <FormGroup check row>
                      <Col sm={{ size: 10, offset: 2 }}>
                        <Button onClick={(e) => this.handleSubmit(e)}>Submit</Button>
                      </Col>
                    </FormGroup>
                  </Form>            
              </ModalBody>
             </Modal>
           
           
  
          </Container>
        </div>
      </>
    );
              }
  }
  
  export default SectionDownload;
  