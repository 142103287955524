   
import React, { Component } from "react";
import Calendar from 'react-calendar';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

// reactstrap components
import { Button, Container, Row, Col , FormGroup ,InputGroup ,InputGroupAddon ,InputGroupText,Input , Alert} from "reactstrap";
import * as emailjs from 'emailjs-com'

// core components
const date = new Date();

class SectionExamples extends Component{
  constructor(props){
    super(props);

    this.state = {
      email: "",
      number: "",
      day: "",
      time: ['18:00', '18:30'],
      clickedDay: false,
      clickedHour: false,
      sent: false,
    }
    this.hourChange = this.hourChange.bind(this)
    this.dayChange = this.dayChange.bind(this)

  }

  hourChange(e){

    this.setState({time: e, clickedHour: true})
  }

  dayChange(e){
    var ok = e.toLocaleDateString();
    this.setState({day: ok,clickedDay: true})
  }

  numberChange(e){
    this.setState({number: e.target.value})
  }
  emailChange(e){

    this.setState({email: e.target.value})
  }
  handleSubmit(event) {
    event.preventDefault();
    var message = "<p>Nouveau rendez vous de " + this.state.email+ " le "+ this.state.day +
                    " entre " + this.state.time[0] +" et " +this.state.time[0] + ".</p>"+
                    "<p>Numero de téléphone: "+ this.state.number +"<p>";

    let templateParams = {
      reply_to: this.state.email,
      from_name: this.state.email,
      to_name: "envoldesign",
      message_html: message,
     }

    if(this.state.email && this.state.number && this.state.day !== "" ){
      emailjs.send(
        'gmail',
        'template_HGcB8pQH',
         templateParams,
        'user_T1h15TiFU10JIjO1F7ZAH'
       )
       this.setState({sent: true})
    } else {
      this.setState({sent: "error"})
    }
    
  };
  

  render(){
    return (
      <>
        <div className="section section-dark">
          <Container>
            <Row >
              <div style={{color: "white", textAlign:"center"}}>
                <h1 > Prenez un rendez-vous téléphonique avec nous</h1>

              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

            </Row>
            <Row className="example-page">
              <Col className="text-center" md="6">
                <h2 className="title">Faite nous confiance</h2>
                <p className="description">
                Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais 
                  s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il 
                  a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant 
                  des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.
                  </p>    
                  <h2 className="title">Conseils, Creation, Realistion, Envol Design s'occupe de vous !</h2>
             
              </Col>
              <Col className="text-left" md="6">
              <FormGroup>
              <h2 style={{color: "white"}} > Jour </h2>
                  <br></br>
                      <InputGroup className="date" id="datetimepicker">
                        <Calendar
                          onChange={(e) => this.dayChange(e)}
                          locale="fr"
                          value={date}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <span className="glyphicon glyphicon-calendar">
                              <i aria-hidden={true} className="fa fa-calendar" />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <hr></hr>
                      <h2 style={{color: "white"}} > Heure </h2>
                      <br></br>
  
                      <InputGroup  className="date" id="datetimepicker">
  
                          <div style={{backgroundColor: "white"}}>
                          <TimeRangePicker
                                    locale="fr"
                                    onChange={(e) => this.hourChange(e)}
                                    value={this.state.time}
                                    disableClock={false}
                                  />
                          </div>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <span className="glyphicon glyphicon-calendar">
                              <i aria-hidden={true} className="fa fa-clock-o" />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <hr></hr>
                      <h2 style={{color: "white"}} > Contact </h2>

                      <br></br>
                      <br></br>
                      <InputGroup  className="date" id="datetimepicker">
                      <Input onChange={(e) => this.emailChange(e)} placeholder="E-mail" type="email"/>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <span className="glyphicon glyphicon-calendar">
                              <i aria-hidden={true} className="fa fa-user" />&nbsp;
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <br></br>
                      <br></br>
                      <InputGroup  className="date" id="datetimepicker">
                      <Input onChange={(e) => this.numberChange(e)} placeholder="Numero de téléphone" type="text"/>

                        <InputGroupAddon addonType="append">
                          <InputGroupText>

                            <span className="glyphicon glyphicon-calendar">
                              <i aria-hidden={true} className="fa fa-phone" />&nbsp;
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>

                    <div className="col text-center">
                      {this.state.clickedDay === true &&
                        <p style={{color: "white" , fontWeight: "bold"}}> Rendez vous le {this.state.day}</p>

                      }
                      {this.state.clickedHour === true &&
                      <p style={{color: "white",fontWeight:"bold" }}> Entre {this.state.time[0]} et {this.state.time[1]}  </p>
                      }
                  <hr></hr>

                  <Button
                    className="btn-round"
                    outline
                    color="neutral"
                    href="#"
                    size="lg"
                    target="_blank"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Confirmer le rendez-vous
                  </Button>
                </div>
                {this.state.sent === true &&
                <div>
                  <br></br>
                      <Alert color="success">
                        <p style={{color: "white", fontWeight: "bold", textAlign: "center"}}><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Votre rendez-vous est confirmé !</p>

                      </Alert>
                </div>
                  }
              {this.state.sent === "error" &&
                <div>
                  <br></br>
                      <Alert color="danger">
                        <p style={{color: "white", fontWeight: "bold", textAlign: "center"}}><i class="fa fa-times" aria-hidden="true"></i>&nbsp;Votre rendez-vous n'a pas pu être confirmé !</p>

                      </Alert>
                </div>
                  }
              </Col>

            </Row>

   
          </Container>
        </div>{" "}
      </>
    );
  }  
}

export default SectionExamples;
