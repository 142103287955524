   
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import conseil from "../../assets/img/image.png"
import creation from "../../assets/img/image2.png"

function SectionButtons() {

  return (
    <>
        <Container>
          <Row>
            <Col lg={6}>
              <h1> Conseil.</h1>
                <p>
                  Votre site web n'est pas seulement une URL. C'est un élément essentiel de votre stratégie marketing et commerciale. 
                  C'est une extension de votre marque et une expérience virtuelle pour vos consommateurs. 
                  Lorsque vous choisissez une équipe, vous avez besoin de personnes de confiance pour exécuter chaque étape, au plus haut niveau de qualité.  </p>
                <br></br>
                <p>Profitez donc d’un pool d’expert à votre disposition, afin de suivre et maîtriser l’ensemble des challenges digitaux de votre entreprise. 
                  Envol Design vous propose un accompagnement sur-mesure pour répondre à tous vos besoins, 
                  c’est avec notre expérience et vos besoins que nous définirons la stratégie digitale pour votre entreprise.
                </p>
            </Col>
            <Col lg={6}>
                <img class="img-fluid" style={{ paddingTop: 20}} src={conseil} alt="conseil"></img>
            </Col>
          </Row>
          <Row style={{paddingTop: 30}}>
            <Col lg={6}>
                <img class="img-fluid" style={{ paddingTop: 20}} src={creation} alt="creation"></img>
            </Col>
            <Col lg={6}>
              <h1> Creation.</h1>
                <p>
                  Votre site web représente votre marque. Un site qui est lent, peu fiable ou difficile à utiliser, c'est un site que les clients et les prospects éviteront. 
                  Les équipes internes souffrent également lorsque de petites modifications du site web sont terriblement lentes, et qu'il n'est pas question de faire de grands changements. 
                </p>
                  <br></br>
                  <p>
                    C’est sur l’ensemble des aspects évoqués qu’Envol Design vous propose son expertise, 
                    nous proposons des services de développement de sites web à la pointe des technologies actuelles. 
                    Nos développeurs web sont spécialisés dans toutes les technologies Web du moment, telles que WordPress, Shopify et d'autres. 
                    De même, nous développons des sites web et applications sur mesures.
                </p>        
            </Col>
          </Row>
          <Row style={{paddingTop: 30}}>
            <Col xs={12}>
                <div style={{textAlign: "center" , paddingTop: 30}}>
                  <img style={{ paddingTop: 20, height: "100%", width: "100%"}} src={conseil} alt="conseil"></img>
                </div>
                <div style={{textAlign: "center" , paddingBottom: 30}}>
                  <h1>
                      Livraison.
                  </h1>
                  <p>
                    Présenter, Valider et Livrer. L’ensemble de ces étapes se feront ensemble pour la livraison d’un produit clé en main et répondant à votre demande
                    Cependant, la livraison d’un site web ou d’une application ne signe pas la fin du projet ou d’une prestation. 
                    Envol Design vous accompagne durant la prise en main applicative et vous propose un suivi pour la maintenance évolutive. 
                  </p>
                </div>
            </Col>
          </Row>
        </Container>

    </>
  );
}

export default SectionButtons;
