   
import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
// import focus from '../../assets/img/focus.jpeg'
// core components
import * as emailjs from 'emailjs-com'

export default class SectionLogin extends Component {

  constructor(props){
    super(props);
    this.state = {
      message: "",
      name:"",
      email: "",
      sent: false
    }
    this.nameChange = this.nameChange.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.messageChange = this.messageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }
  
  nameChange(e){
    this.setState({name: e.target.value})
  }
  emailChange(e){

    this.setState({email: e.target.value})
  }
  messageChange(e){

    this.setState({message: e.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();

    let templateParams = {
      reply_to: this.state.email,
      from_name: this.state.name,
      to_name: "envoldesign",
      message_html: this.state.message +".<br></br><br></br> from:"+this.state.email,
     }

     if(this.state.email && this.state.name && this.state.message !== "" ){
      emailjs.send(
        'gmail',
        'template_HGcB8pQH',
         templateParams,
        'user_T1h15TiFU10JIjO1F7ZAH'
       )
       this.setState({sent: true})
    } else {
      this.setState({sent: "error"})
    }
  };
  

render() {
  return (
    <>
      <div
        className="section section-image section-login"
        style={{
          backgroundImage: "url(" + require("assets/img/focus.jpeg") + ")"
        }}
      >
        <Container>
          <Row style={{color: "white"}}>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Redigez en quelques lignes vos besoin</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label >Nom Prenom</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={(e) => this.nameChange(e)} placeholder="Nom Prenom" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>E-mail</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input  placeholder="E-mail" type="text" onChange={(e) => this.emailChange(e)}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <label >Message</label>
                  <Input
                    placeholder="Racontez-nous vos rêves les plus fou..."
                    type="textarea"
                    rows="4"
                    onChange={(e) => this.messageChange(e)}

                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button onClick={(e) => this.handleSubmit(e)} className="btn-fill" color="danger" size="lg">
                        Envoyer
                      </Button>

                    </Col>
     
                  </Row>
                  <br></br>
                  {this.state.sent === true &&
                    <Alert color="success">
                      <p style={{color: "white", fontWeight: "bold", textAlign: "center"}}><i class="fa fa-paper-plane-o" aria-hidden="true"></i>&nbsp;Votre idée s'est bien envolée !</p>

                    </Alert>
                  }
                  {this.state.sent === "error" &&
                    <Alert color="danger">
                      <p style={{color: "white", fontWeight: "bold", textAlign: "center"}}><i class="fa fa-times" aria-hidden="true"></i>&nbsp;Votre idée n'a pas pu s'envoler.</p>

                    </Alert>
                  }
                  
                </Form>
              </Col>
            </Row>
            {/* <div className="col text-right">
                <Button
                  className="btn-round"
                  outline
                  color="neutral"
                  href="#"
                  size="lg"
                  target="_blank"
                >
                  Demandez conseil
                </Button>
              </div> */}
        </Container>
      </div>{" "}
    </>
  );
}
  
}